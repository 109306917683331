<template>
  <div class="member-index">
    <el-tabs type="card" v-model="type" @tab-click="handleTabChange">
      <!-- <el-tab-pane label="全部" name=""></el-tab-pane> -->
      <!-- <el-tab-pane label="充值" name="topup"></el-tab-pane> -->
      <!-- <el-tab-pane label="购买" name="buy"></el-tab-pane> -->
      <el-tab-pane label="收入" name="income"></el-tab-pane>
      <el-tab-pane label="提现" name="withdraw"></el-tab-pane>
    </el-tabs>
    <div class="search-wrapper">
      <div></div>
      <!-- <el-form inline>
        <el-form-item label="时间范围">
          <el-radio v-model="quickTime" :label="15" @change="handleQuickChange(15)">最近15天</el-radio>
          <el-radio v-model="quickTime" :label="90" @change="handleQuickChange(90)">最近三个月</el-radio>
          <el-date-picker
            v-model="pickTime"
            type="month"
            :picker-options="options"
            placeholder="选择查询时间"
            @change="handlePickerChange"
            :clearable="false"
          ></el-date-picker>
        </el-form-item>
      </el-form> -->
      <div class="xf-wrapper">
        <!-- <el-date-picker type="daterange" v-model="pickTime"></el-date-picker> -->
        <el-date-picker
          @change="timeChange"
          type="daterange"
          :clearable="false"
          v-model="pickTime"
          :picker-options="pickerOptions"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
        <!-- <p v-html="getAmount"></p>
        <el-input
          placeholder="请输入订单号"
          v-model="searchForm.orderId"
          style="width: 200px;"
          @change="handleSearch"
        ></el-input> -->
      </div>
    </div>
    <el-table
      border
      align="center"
      v-if="type === 'income'"
      key="income"
      :data="list"
    >
      <el-table-column
        label="交易编号"
        align="center"
        width="150"
        prop="id"
      ></el-table-column>
      <el-table-column
        label="商品名"
        align="center"
        width="150"
        prop="name"
      ></el-table-column>
      <el-table-column
        label="类型"
        align="center"
        width="150"
        prop="TypeName"
      ></el-table-column>
      <el-table-column
        label="金额"
        align="center"
        width="150"
        prop="MoneyChange"
      ></el-table-column>
      <el-table-column
        label="时间"
        align="center"
        width="150"
        prop="CreationTime"
      ></el-table-column>
      <el-table-column label="状态" align="center" width="80" prop="state">
        <template slot-scope="scope">
          <el-tag
            :type="
              scope.row.state == 0
                ? 'primary'
                : scope.row.state == 1
                ? 'success'
                : 'info'
            "
            >{{
              scope.row.state == 0
                ? "审核中"
                : scope.row.state == 1
                ? "完成"
                : "失败"
            }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        label="备注"
        align="center"
        prop="remark"
      ></el-table-column>
    </el-table>
    <el-table border align="center" v-else key="other" :data="list">
      <el-table-column
        label="交易编号"
        align="center"
        width="150"
        prop="id"
      ></el-table-column>
      <!-- <el-table-column label="商品名" align="center" width="150" prop="name"></el-table-column> -->
      <!-- <el-table-column
        label="类型"
        align="center"
        width="150"
        prop="TypeName"
      ></el-table-column> -->
      <el-table-column
        label="金额"
        align="center"
        width="150"
        prop="MoneyChange"
      ></el-table-column>
      <el-table-column
        label="时间"
        align="center"
        width="150"
        prop="CreationTime"
      ></el-table-column>
      <el-table-column label="状态" align="center" width="120" prop="state">
        <template slot-scope="scope">
          <el-tag
            :type="
              scope.row.state == 0
                ? 'primary'
                : scope.row.state == 1
                ? 'success'
                : 'info'
            "
            >{{
              scope.row.state == 0
                ? "审核中"
                : scope.row.state == 1
                ? "完成"
                : "失败"
            }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        label="备注"
        align="center"
        prop="remark"
      ></el-table-column>
    </el-table>
    <el-pagination
      :page-size="searchForm.pagenum"
      :current-page.sync="searchForm.page"
      :total="total"
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
import { getDealList } from "@/api";
import moment from "moment";
export default {
  data() {
    return {
      options: {
        disabledDate(val) {
          return val > Date.now();
        },
      },
      quickTime: 15,
      pickTime: [moment().subtract(15, "day").valueOf(), moment().valueOf()],
      type: "income",
      list: [],
      total: 0,
      amount: 0,
      pickerOptions: {
        disabledDate(val) {
          return (
            val < moment("2022-01-01 00:00:00").valueOf() ||
            val > moment().endOf("day")
          );
        },
      },
      searchForm: {
        searchKey: "",
        page: 1,
        pagenum: 10,
        begin: "",
        end: "",
      },
    };
  },
  mounted() {
    this.searchForm.begin = moment()
      .subtract(15, "day")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    this.searchForm.end = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
    this.getData();
  },
  computed: {
    getAmount() {
      if (!this.type || !this.amount) return "";
      switch (this.type) {
        case "coinConsume":
          return `消费 <span class='amount'>${this.amount}</span>硬币`;
        case "coinIncome":
          return `收入 <span class='amount'>${this.amount}</span>硬币`;
        case "coinRecharge":
          return `充值 <span class='amount'>${this.amount}</span>硬币`;
        case "scoreConsume":
          return `消耗 <span class='amount'>${this.amount}</span>积分`;
        case "scoreIncome":
          return `获得 <span class='amount'>${this.amount}</span>积分`;
        default:
          return ``;
      }
    },
  },
  methods: {
    timeChange(val) {
      // console.log("val >>", val);
      this.searchForm.begin = moment(val[0]).format("YYYY-MM-DD HH:mm:ss");
      this.searchForm.end = moment(val[1]).format("YYYY-MM-DD HH:mm:ss");
      this.getData();
    },
    async getData() {
      const res = await getDealList({
        ...this.searchForm,
        action: this.type,
      });
      this.list = res.data;
      this.total = res.count;
      // this.amount = res.count;
    },
    handleQuickChange(day) {
      this.pickTime = "";
      this.searchForm.begin = moment()
        .subtract(day, "day")
        .startOf("day")
        .valueOf();
      this.searchForm.end = moment().valueOf();
      this.searchForm.page = 1;
      this.getData();
    },
    handlePickerChange(val) {
      this.quickTime = "";
      this.searchForm.begin = moment(val).startOf("month").valueOf();
      this.searchForm.end = moment(val).endOf("month").valueOf();
      this.searchForm.page = 1;
      this.getData();
    },
    handleSearch() {
      this.searchForm.page = 1;
      this.getData();
    },
    handleTabChange(tab) {
      this.type = tab.name;
      this.amount = 0;
      this.quickTime = 15;
      // this.pickTime = '';
      this.pickTime = [
        moment().subtract(15, "day").valueOf(),
        moment().valueOf(),
      ];
      this.list = [];
      this.searchForm = {
        searchKey: "",
        page: 1,
        pagenum: 10,
        begin: moment()
          .subtract(15, "day")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        end: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      };
      this.getData();
    },
    handleCurrentChange(page) {
      this.searchForm.page = page;
      console.log("page >>", page);
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.member-index {
  padding: 10px 20px;
  background: #fff;
  min-height: 561px;
  ::v-deep {
    .el-form-item--small.el-form-item {
      margin-bottom: 9px;
    }
    .el-radio__input {
      display: none;
    }
  }
  .custom-select {
    width: 120px;
    margin: 0 6px;
  }
  .search-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .xf-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    p {
      margin-right: 5px;
      ::v-deep {
        span,
        .amount {
          font-size: 18px;
          margin: 0 3px;
          color: #f80000;
        }
      }
    }
  }
  ::v-deep {
    .el-pagination {
      text-align: right;
      padding: 10px 0;
    }
  }
}
</style>
